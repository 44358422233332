import Button from '@ingka/button'
import arrowRight from '@ingka/ssr-icon/paths/arrow-right'
import Table, { TableBody, TableHeader } from '@ingka/table'
import Text from '@ingka/text'
import { Group } from '@mantine/core'
import { orderBy } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { CoachingSessionHelper } from 'lib/utils/coachingSession.helper'

import { useSectionPaths } from 'hooks/navigation/useSectionPaths.hooks'

import { useGetTeamleadCustomerComments } from 'state/slices/api'

import { CoworkersOverviewStatus } from 'components/composites/CoworkersOverviewStatus'
import { NumberTag } from 'components/composites/Shared/NumberTag'
import { Widget } from 'components/composites/Shared/Widget/Widget.styling'
import { Heading } from 'components/primitives/Text'

import CoworkerPhoto from '../CoworkerPhoto/CoworkerPhoto.component'
import NextScheduledCoaching from '../CoworkerStatus/NextCoachingStatus.component'
import * as Styled from './CoworkersTable.styles'
import { CoworkerTableProps, TableRowProps } from './CoworkersTable.types'

const CoworkersTable: React.FC<CoworkerTableProps> = ({ coworkers }) => {
  const { t } = useTranslation()

  return (
    <>
      <Widget>
        <NumberTag
          // NOTE: I am wondering whether this should go into translations? In many cases we want to use "Coworkers"
          // instead of the local translation, and this allows us to select plural vs singular based on the number of coworkers.
          count={<Heading>{coworkers.length}</Heading>}
          text={<Heading>{`Co-worker${coworkers.length === 1 ? '' : 's'}`}</Heading>}
        />
        <Table fullWidth inset>
          <TableHeader>
            <Styled.TableRowHeader>
              <th>{t('features.coworker.coworkers-overview.name')}</th>
              <th>{t('features.coworker.coworkers-overview.action')}</th>
              <th>{t('features.coworker.coworkers-overview.next-session')}</th>
              <th>{t('features.coworker.coworkers-overview.customer-comments')}</th>
              <th>{t('features.coworker.coworkers-overview.go-to-coworker')}</th>
            </Styled.TableRowHeader>
          </TableHeader>
          <TableBody>
            {orderBy(
              coworkers,
              (coworker) => {
                // Get session with latest planned date
                const upcomingCoachingSession = CoachingSessionHelper.getCurrentCoachingSession(
                  coworker.coachingSessions ?? []
                )
                // Return the planned date if status is 'planned', if not return '1' to ensure placement at top of table
                return upcomingCoachingSession?.status === 'planned'
                  ? upcomingCoachingSession?.plannedDate
                  : '1'
              },
              'asc'
            )?.map((coworker) => {
              return <TableRow key={coworker.legacyId} coworker={coworker} active={true} />
            })}
          </TableBody>
        </Table>
      </Widget>
    </>
  )
}

const TableRow: React.FC<TableRowProps> = ({ active, coworker }) => {
  const { teamleadPath } = useSectionPaths()
  const nav = useNavigate()

  const { data: customerComments = [], isLoading: isCustomerCommentsLoading } =
    useGetTeamleadCustomerComments(coworker.legacyId) ?? {}

  return (
    <Styled.TableRow active={active}>
      <Styled.TableCell style={{ fontWeight: 'bold' }}>
        <Group wrap="wrap">
          <CoworkerPhoto coworker={coworker} />
          {coworker.fullName}
        </Group>
      </Styled.TableCell>
      <Styled.TableCell>
        <CoworkersOverviewStatus coworkerId={coworker.legacyId} />
      </Styled.TableCell>
      <Styled.TableCell>
        <NextScheduledCoaching coworkerId={coworker.legacyId} />
      </Styled.TableCell>
      <Styled.TableCell>
        <Button
          small
          text={<Text>{customerComments.length}</Text>}
          onClick={() => nav(`${teamleadPath}/coworkers/${coworker.legacyId}#customer-comments`)}
          loading={isCustomerCommentsLoading}
        />
      </Styled.TableCell>
      <Styled.TableCell>
        <Button
          type="tertiary"
          small
          iconOnly
          ssrIcon={arrowRight}
          onClick={() =>
            nav(`${teamleadPath}/coworkers/${coworker.legacyId}#efficiency`, {
              state: {
                from: teamleadPath,
              },
            })
          }
        />
      </Styled.TableCell>
    </Styled.TableRow>
  )
}

export default CoworkersTable

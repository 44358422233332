import FormField from '@ingka/form-field'
import { Grid } from '@mantine/core'
import dayjs from 'dayjs'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Coworker } from 'lib/types/coworker.types'
import { Mission, MissionStatus } from 'lib/types/mission.types'

import { MissionDraftFormFields } from 'components/features/Missions/MissionDraftEditor/MissionDraftEditor.types'
import { DatePicker } from 'components/primitives/DatePicker'

type Props = {
  form: UseFormReturn<MissionDraftFormFields> & {
    watch: (field: keyof MissionDraftFormFields) => any
  };
  coworker: Coworker
  mission: Mission
}

const DurationStep: React.FC<Props> = ({ form, coworker, mission }) => {
  const { t } = useTranslation()
  const { control, formState, watch, setValue } = form
  const { coachingSessionId } = useParams()

  const coachingSession = coworker?.coachingSessions?.find(
    (session) => session.id === coachingSessionId
  )

  const plannedDate = dayjs(coachingSession?.plannedDate).toDate()
  let startedDate = watch('startedDate')

  // Set startedDate based on mission or plannedDate
  if (!mission || mission.status === MissionStatus.Draft) {
    if (dayjs().isAfter(plannedDate)) {
      startedDate = dayjs().toDate()
    } else {
      startedDate = plannedDate;
    }
  } else if (mission) {
    startedDate = dayjs(mission.startedDate).toDate()
  }

  // Handle start date changes and calculate endDate
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setValue('startedDate', date)
      const calculatedEndDate = dayjs(date).add(30, 'days').toDate(); // 30 days later
      setValue('endDate', calculatedEndDate)
    }
  };

  // Calculate the initial endDate if startedDate exists
  const calculatedEndDate = startedDate
    ? dayjs(startedDate).add(30, 'days').toDate()
    : undefined

  return (
    <Grid>
      <Grid.Col span={6}>
        <Controller
          name="startedDate"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              data-testid="form-field-start-date"
              fieldHelper={{
                msg: t('features.mission.create-mission.fields.start-date.hint'),
              }}
            >
              <DatePicker
                id="startDate"
                data-testid="input-field-startDate"
                label={t('features.mission.create-mission.fields.start-date.title')}
                onChange={(date) => {
                  onChange(date);
                  handleStartDateChange(date);
                }}
                onBlur={onBlur}
               value={value || startedDate || undefined}
              />
            </FormField>
          )}
        />
      </Grid.Col>

      <Grid.Col span={6}>
        <Controller
          name="endDate"
          control={control}
          rules={{
            required: t('features.mission.create-mission.fields.end-date.errors.missing'),
            pattern: {
              value: /^\d{4}-\d{1,2}-\d{1,2}$/,
              message: t(
                'features.mission.create-mission.fields.end-date.errors.invalid-pattern'
              ),
            },
            validate: (value) => {
              return `${value} is a valid end date.`
            },
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <FormField
              data-testid="form-field-end-date"
              fieldHelper={{
                msg: t('features.mission.create-mission.fields.end-date.hint'),
              }}
              shouldValidate={formState.errors.endDate ? true : false}
              valid={formState.errors.endDate?.type === 'validate' ? true : false}
              validation={{
                msg: formState.errors.endDate?.message,
                id: 'form-error-end-date',
                type: 'error',
              }}
            >
              <DatePicker
                id="endDate"
                data-testid="input-field-endDate"
                label={t('features.mission.create-mission.fields.end-date.title')}
                onChange={onChange}
                onBlur={onBlur}
                value={calculatedEndDate || calculatedEndDate} // Auto-set 30 days later
                minDate={startedDate || undefined} // Ensure endDate cannot be earlier than startedDate
              />
            </FormField>
          )}
        />
      </Grid.Col>
    </Grid>
  );
};

export default DurationStep
